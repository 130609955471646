import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Cell, { CellProps } from './designsystem/Cell';
import Heading from './designsystem/Heading';
import TextClamp from './designsystem/TextClamp';
import Marker from './svg/Marker.svg';
import Rating from './Rating';
import { getFontCss } from '@oberoninternal/travelbase-ds/constants/theme';
import { useTenantContext } from '../context/TenantContext';

interface Props extends Omit<CellProps, 'title'> {
    rating?: number;
    title?: ReactNode;
    tagline: ReactNode;
    description?: string;
    withMarker?: boolean;
}

const Intro: FC<React.PropsWithChildren<Props>> = ({ rating, title, tagline, description, withMarker, ...props }) => {
    const { brandConfig } = useTenantContext();

    return (
        <StyledCell pb={[null, null, 5]} {...props}>
            <Heading>
                {rating && brandConfig.hasReviews && <Rating value={rating} />}
                <StyledTitle variant="small">
                    {withMarker && <StyledMarker />}
                    {title}
                    <br />
                    <Tagline>{tagline}</Tagline>
                </StyledTitle>
            </Heading>
            {description && <TextClamp maxLength={200} text={description} variant="huge" />}
        </StyledCell>
    );
};
export default Intro;

const Tagline = styled.span`
    display: block;
    padding-top: ${({ theme }) => theme.spacing['20_Tiny']};
    ${({ theme }) => getFontCss(theme.fontSizes.title.large)};
`;

const StyledCell = styled(Cell)`
    max-width: ${({ theme }) => theme.maxWidths.l};
`;

const StyledTitle = styled(Title)`
    font-weight: 400;
`;

const StyledMarker = styled(Marker)`
    margin-right: ${({ theme }) => theme.spacing['20_Tiny']};
`;
